<template>

<div id="app" ref="app" :class="{'is-loading': is.loading, 'is-location': isLocation, 'is-actually-desktop': is.standalone && window.width >= 760, 'is-standalone': is.standalone, 'is-mobileOnly': mobileOnly && !window.is.mobile, 'is-mobile': !isDesktop, 'is-desktop': isDesktop}">

	<div class="app-background" v-if="mobileOnly && !window.is.mobile" />

	<template v-if="!is.loading">

		<div class="app-panel">

			<com-head :is-embed="is.embed" />

			<router-view></router-view>

		</div>

		<com-content v-if="isDesktop" />

	</template>

</div>

</template>

<script>

import comHead from '@/components/Head'
import comContent from '@/components/Content'

export default {

	name: 'App',

	components: {
		'com-head': comHead,
		'com-content': comContent
	},

	data: function() {

		return {
			is: {
				loading: true,
				embed: false,
				standalone: false
			}
		}

	},

	computed: {

		mobileOnly: function() {

			return this.$store.getters['mobileOnly']

		},

		isLocation: function() {

			return this.$route.meta.isLocation

		},

		route: function() {

			return this.$route

		},

		routeName: function() {

			return this.$route.name

		}

	},

	watch: {

		route: {
			
			deep: true,
			
			handler: function(n, o) {

				this.routeAnalyse()

				if (n.name === 'Group' && o.name === 'Group' && !this.window.is.desktop) window.scrollTo(0, 0)

			}

		},

		routeName: function() {

			if (!this.window.is.desktop && !this.is.standalone) window.scrollTo(0, 0)

		}

	},

	created: async function() {

		var key = ''
		var bugherd = false

		if (this.$route.query.key) {

			this.is.embed = true
			key = this.$route.query.key

			if(this.$route.query.path) {
			
				this.$router.push(this.$route.query.path)

			}

		} else if(this.$route.params.key && process.env.VUE_APP_TYPE === 'preview') {

			this.is.embed = true
			
			await this.$api.get('preview/' + this.$route.params.key).then(function(json) {

				key = json.key
				bugherd = json.bugherd
				this.$store.commit('mobileOnly', json.type === 2)

			}.bind(this))

		} else if(process.env.VUE_APP_TYPE === 'standalone') {

			key = document.getElementById('app').getAttribute('data-key')
			this.is.standalone = true

		}

		await this.$store.dispatch('init', {
			key: key,
			primary: this.$route.query.primary
		})

		this.routeAnalyse()

		this.is.loading = false

		window.addEventListener('message', this.onMessage.bind(this))

		if (process.env.VUE_APP_TYPE === 'embed') {

			const resizeObserver = new ResizeObserver(function() {

				window.parent.postMessage({
					type: 'height',
					height: document.getElementById('app').clientHeight
				}, '*')

			})

			resizeObserver.observe(document.getElementById('app'));

		} else if (process.env.VUE_APP_TYPE === 'preview' && bugherd) {

			var bugherdIntegration = document.createElement('script')
			bugherdIntegration.setAttribute('src', 'https://www.bugherd.com/sidebarv2.js?apikey=' + bugherd)
			document.head.appendChild(bugherdIntegration)

		}

	},

	beforeDestroy: async function() {

		window.removeEventListener('message', this.onMessage.bind(this))

	},

	methods: {

		onMessage: function(e) {

			if(e.data.path) {

				this.$router.push(e.data.path).catch(function() {})
			
			} else {

				this.$router.push({
					name: e.data.name,
					params: e.data.params
				}).catch(function() {})
			
			}

		},

		routeAnalyse: function() {

			if (process.env.VUE_APP_TYPE === 'preview') {

				window.parent.postMessage({
					type: 'route',
					name: this.$route.name,
					params: this.$route.params
				}, '*')

			} else if (process.env.VUE_APP_TYPE === 'embed') {

				// eslint-disable-next-line
				gtag('event', 'page_view', {
					page_location: window.location.href.replace('.com/', '.com/' + this.$store.getters['guide'].slug + '/embed/'),
					send_to: process.env.VUE_APP_ANALYTICS
				})

				window.parent.postMessage({
					type: 'route',
					path: this.$route.path
				}, '*')

			} else if (process.env.VUE_APP_TYPE === 'standalone') {

				// eslint-disable-next-line
				gtag('event', 'page_view', {
					page_location: window.location.href.replace('.com/', '.com/' + this.$store.getters['guide'].slug + '/embed/'),
					send_to: process.env.VUE_APP_ANALYTICS
				})
				
			}

			if (this.$route.query.collapse) {
					
				this.$store.commit('category', false)

			} else {

				this.$store.commit('category', (this.$route.params.category) ? this.$_.findWhere(this.$store.getters['categories'], {
					slug: this.$route.params.category
				}).id : this.$store.getters['categories'][0].id)

			}

			this.$store.commit('group', (this.$route.params.group) ? this.$_.findWhere(this.$store.getters['groups'], {
				slug: this.$route.params.group
			}).id : false)

			this.$store.commit('item', (this.$route.params.item) ? this.$_.findWhere(this.$store.getters['items'], {
				slug: this.$route.params.item
			}).id : false)

			this.$store.commit('location', (this.$route.params.location) ? this.$_.findWhere(this.$store.getters['locations'], {
				slug: this.$route.params.location
			}).id : false)

			if (process.env.VUE_APP_TYPE === 'embed') {

				window.parent.postMessage({
					type: 'top'
				}, '*')

			} else if(process.env.VUE_APP_TYPE === 'standalone') {
			
				window.scrollTo(0, 0)
			
			}
					
		}

	}

}

</script>

<style>

#bablicFlagsWidget {
	display: none!important;
}

#app.is-location {
	overflow: hidden;
}

#app.is-mobileOnly {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	right: 0px;
	top: 0px;
	bottom: 0px;
	left: 0%;
	background-color: #434343;
	background-image: linear-gradient(#5480D3, #3256A7);
	transition: left 300ms ease-in-out;
}

#app.is-mobileOnly .app-panel {
	box-sizing: initial;
	background-color: #fff;
	overflow-y: auto;
	z-index: 1;
	border-radius: 16px;
	border: 6px solid #000;
	box-shadow: 0 4px 24px 0 rgb(0 0 0 / 25%);
	width: 375px;
	max-height: 667px;
	height: calc(100% - 64px);
}

#app.is-mobileOnly.is-location .app-panel {
	overflow: hidden;
}

.app-background {
	background-color: transparent;
    background-image: 
    linear-gradient(0deg, transparent 9%, 
            rgba(255, 255, 255, .2) 10%, rgba(255, 255, 255, .2) 12%, transparent 13%, transparent 29%, 
            rgba(255, 255, 255, .1) 30%, rgba(255, 255, 255, .1) 31%, transparent 32%, transparent 49%, 
            rgba(255, 255, 255, .1) 50%, rgba(255, 255, 255, .1) 51%, transparent 52%, transparent 69%, 
            rgba(255, 255, 255, .1) 70%, rgba(255, 255, 255, .1) 71%, transparent 72%, transparent 89%,
            rgba(255, 255, 255, .1) 90%, rgba(255, 255, 255, .1) 91%, transparent 92%, transparent),
    linear-gradient(90deg, transparent 9%, 
            rgba(255, 255, 255, .2) 10%, rgba(255, 255, 255, .2) 12%, transparent 13%, transparent 29%, 
            rgba(255, 255, 255, .1) 30%, rgba(255, 255, 255, .1) 31%, transparent 32%, transparent 49%, 
            rgba(255, 255, 255, .1) 50%, rgba(255, 255, 255, .1) 51%, transparent 52%, transparent 69%, 
            rgba(255, 255, 255, .1) 70%, rgba(255, 255, 255, .1) 71%, transparent 72%, transparent 89%,
            rgba(255, 255, 255, .1) 90%, rgba(255, 255, 255, .1) 91%, transparent 92%, transparent);
	background-size:50px 50px;
}

#app.is-mobileOnly .app-background {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
}

.is-desktop .app-panel {
	position: fixed;
	left: 0px;
	width: 400px;
	bottom: 0px;
	overflow-y: auto;
	top: 0px;
	z-index: 2;
}

</style>
